<template>
    <v-container fluid> <QuickReports /> </v-container>
</template>

<script>
import QuickReports from '@/components/QuickreportsForm.vue'

export default {
    name: 'QReports',
    components: {
        QuickReports,
    },
}
</script>